let middlewareIndex = 0;
const middlewareStack = [];
const middlewareLookup = {};
export function addLoggingMiddleware(middleware) {
  const newMiddlewareId = middlewareIndex++;
  middlewareStack.push(middleware);
  middlewareLookup[newMiddlewareId] = middleware;
  return newMiddlewareId;
}
export function removeLoggingMiddleware(middlewareOrId) {
  const middlewareRef = typeof middlewareOrId === 'number' ? middlewareLookup[middlewareOrId] : middlewareOrId;
  const index = middlewareStack.indexOf(middlewareRef);
  return !!middlewareStack.splice(index, 1);
}
export function applyMiddleware(logEvent) {
  return middlewareStack.reduce((event, middleware) => event ? middleware(event) : event, logEvent);
}
export function _clear() {
  middlewareStack.length = 0;
}