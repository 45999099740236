import { logGlassbox, buildGlassboxPayload } from '../glassbox.client.logger';

function _log(schemaId, eventType, eventName, params) {
  return logGlassbox(buildGlassboxPayload('Analytics', schemaId, 'Js', eventType, eventName, params));
}

export function logAcceptAllCookies() {
  return _log(24499, 'AcceptAllCookies', 'AcceptAllCookiesWebJs');
}
export function logAcceptPrivacyPolicy() {
  return _log(15700, 'AcceptPrivacyPolicy', 'AcceptPrivacyPolicyWebJs');
}
export function logAcceptTeamInvitation(params) {
  return _log(13975, 'AcceptTeamInvitation', 'AcceptTeamInvitationWebJs', params);
}
export function logAcceptTermsofService() {
  return _log(15702, 'AcceptTermsofService', 'AcceptTermsofServiceWebJs');
}
export function logAcknowledgeNotificationCenterOnboarding() {
  return _log(22222, 'AcknowledgeNotificationCenterOnboarding', 'AcknowledgeNotificationCenterOnboardingWebJs');
}
export function logActivatedAbTest(params) {
  return _log(24232, 'ActivatedAbTest', 'ActivatedAbTestWebJs', params);
}
export function logActiveFeatureSwitches(params) {
  return _log(15206, 'ActiveFeatureSwitches', 'ActiveFeatureSwitchesWebJs', params);
}
export function logAddCollaboratorFailed(params) {
  return _log(17278, 'AddCollaboratorFailed', 'AddCollaboratorFailedWebJs', params);
}
export function logAddCollaborator(params) {
  return _log(23715, 'AddCollaborator', 'AddCollaboratorWebJs', params);
}
export function logAddDocumentToFolder(params) {
  return _log(23722, 'AddDocumentToFolder', 'AddDocumentToFolderWebJs', params);
}
export function logAddFolderCollaboratorFailed(params) {
  return _log(15203, 'AddFolderCollaboratorFailed', 'AddFolderCollaboratorFailedWebJs', params);
}
export function logAddFolderCollaboratorSuccess(params) {
  return _log(22184, 'AddFolderCollaboratorSuccess', 'AddFolderCollaboratorSuccessWebJs', params);
}
export function logAddFolderCollaborator(params) {
  return _log(15201, 'AddFolderCollaborator', 'AddFolderCollaboratorWebJs', params);
}
export function logAddGramToFolder(params) {
  return _log(17308, 'AddGramToFolder', 'AddGramToFolderWebJs', params);
}
export function logAddNewIntegration(params) {
  return _log(24121, 'AddNewIntegration', 'AddNewIntegrationWebJs', params);
}
export function logAddPersonalVideoReferralInvite() {
  return _log(18366, 'AddPersonalVideoReferralInvite', 'AddPersonalVideoReferralInviteWebJs');
}
export function logAddPresentationToFolder(params) {
  return _log(14000, 'AddPresentationToFolder', 'AddPresentationToFolderWebJs', params);
}
export function logAddReferralInvitePersonalNote() {
  return _log(18362, 'AddReferralInvitePersonalNote', 'AddReferralInvitePersonalNoteWebJs');
}
export function logAddedNewIntegration(params) {
  return _log(24377, 'AddedNewIntegration', 'AddedNewIntegrationWebJs', params);
}
export function logAllowBrowserNotifications() {
  return _log(14872, 'AllowBrowserNotifications', 'AllowBrowserNotificationsWebJs');
}
export function logAllowCameraFailure() {
  return _log(22684, 'AllowCameraFailure', 'AllowCameraFailureWebJs');
}
export function logAllowCameraSuccess() {
  return _log(22686, 'AllowCameraSuccess', 'AllowCameraSuccessWebJs');
}
export function logAllowToRequestBrowserNotificationsPermission() {
  return _log(14875, 'AllowToRequestBrowserNotificationsPermission', 'AllowToRequestBrowserNotificationsPermissionWebJs');
}
export function logAnsweredWorkLocationSurvey(params) {
  return _log(22840, 'AnsweredWorkLocationSurvey', 'AnsweredWorkLocationSurveyWebJs', params);
}
export function logApplyDateRangeDesignAnalytics(params) {
  return _log(24533, 'ApplyDateRangeDesignAnalytics', 'ApplyDateRangeDesignAnalyticsWebJs', params);
}
export function logAutoZoom(params) {
  return _log(14582, 'AutoZoom', 'AutoZoomWebJs', params);
}
export function logBackOnWelcomeScreen(params) {
  return _log(20608, 'BackOnWelcomeScreen', 'BackOnWelcomeScreenWebJs', params);
}
export function logBackToGallery(params) {
  return _log(24576, 'BackToGallery', 'BackToGalleryWebJs', params);
}
export function logBackToOutline(params) {
  return _log(17959, 'BackToOutline', 'BackToOutlineWebJs', params);
}
export function logBlockBrowserNotifications() {
  return _log(14880, 'BlockBrowserNotifications', 'BlockBrowserNotificationsWebJs');
}
export function logBrandkitError(params) {
  return _log(18868, 'BrandkitError', 'BrandkitErrorWebJs', params);
}
export function logCalloutClicked(params) {
  return _log(15214, 'CalloutClicked', 'CalloutClickedWebJs', params);
}
export function logCancelClassicToNextCopy() {
  return _log(19578, 'CancelClassicToNextCopy', 'CancelClassicToNextCopyWebJs');
}
export function logCancelSamlActivation(params) {
  return _log(16451, 'CancelSamlActivation', 'CancelSamlActivationWebJs', params);
}
export function logCancelSubscription() {
  return _log(14010, 'CancelSubscription', 'CancelSubscriptionWebJs');
}
export function logCarouselThumbnailClicked(params) {
  return _log(15111, 'CarouselThumbnailClicked', 'CarouselThumbnailClickedWebJs', params);
}
export function logChangeAccountPassword() {
  return _log(14012, 'ChangeAccountPassword', 'ChangeAccountPasswordWebJs');
}
export function logChangeCreditCard() {
  return _log(14014, 'ChangeCreditCard', 'ChangeCreditCardWebJs');
}
export function logChangeFrame(params) {
  return _log(22680, 'ChangeFrame', 'ChangeFrameWebJs', params);
}
export function logChangeLanguage(params) {
  return _log(17826, 'ChangeLanguage', 'ChangeLanguageWebJs', params);
}
export function logChangeOrientation(params) {
  return _log(17573, 'ChangeOrientation', 'ChangeOrientationWebJs', params);
}
export function logChangePaymentMethodFailure(params) {
  return _log(22361, 'ChangePaymentMethodFailure', 'ChangePaymentMethodFailureWebJs', params);
}
export function logChangePaymentMethodSuccess(params) {
  return _log(22359, 'ChangePaymentMethodSuccess', 'ChangePaymentMethodSuccessWebJs', params);
}
export function logChangeProfileVisibility(params) {
  return _log(14058, 'ChangeProfileVisibility', 'ChangeProfileVisibilityWebJs', params);
}
export function logChangeTeamAccess(params) {
  return _log(17282, 'ChangeTeamAccess', 'ChangeTeamAccessWebJs', params);
}
export function logChangeTeamPrivacy(params) {
  return _log(14066, 'ChangeTeamPrivacy', 'ChangeTeamPrivacyWebJs', params);
}
export function logChangeTeamTracking(params) {
  return _log(15227, 'ChangeTeamTracking', 'ChangeTeamTrackingWebJs', params);
}
export function logChangeTeamViewerIdentification(params) {
  return _log(15229, 'ChangeTeamViewerIdentification', 'ChangeTeamViewerIdentificationWebJs', params);
}
export function logChangeViewMode(params) {
  return _log(22682, 'ChangeViewMode', 'ChangeViewModeWebJs', params);
}
export function logChangedAccountInformation(params) {
  return _log(14016, 'ChangedAccountInformation', 'ChangedAccountInformationWebJs', params);
}
export function logChangedAccountPassword() {
  return _log(14018, 'ChangedAccountPassword', 'ChangedAccountPasswordWebJs');
}
export function logChangedAnalyticsTracking(params) {
  return _log(17160, 'ChangedAnalyticsTracking', 'ChangedAnalyticsTrackingWebJs', params);
}
export function logChangedCookieConsent(params) {
  return _log(24501, 'ChangedCookieConsent', 'ChangedCookieConsentWebJs', params);
}
export function logChangedFolderName(params) {
  return _log(14026, 'ChangedFolderName', 'ChangedFolderNameWebJs', params);
}
export function logChangedIntegrationEnablementState(params) {
  return _log(24379, 'ChangedIntegrationEnablementState', 'ChangedIntegrationEnablementStateWebJs', params);
}
export function logChangedPresentationTitle(params) {
  return _log(14034, 'ChangedPresentationTitle', 'ChangedPresentationTitleWebJs', params);
}
export function logChangedSessionTimeoutSetting(params) {
  return _log(22378, 'ChangedSessionTimeoutSetting', 'ChangedSessionTimeoutSettingWebJs', params);
}
export function logChangedViewLinkName(params) {
  return _log(17146, 'ChangedViewLinkName', 'ChangedViewLinkNameWebJs', params);
}
export function logChangedViewerIdentification(params) {
  return _log(17168, 'ChangedViewerIdentification', 'ChangedViewerIdentificationWebJs', params);
}
export function logClickCta(params) {
  return _log(17381, 'ClickCta', 'ClickCtaWebJs', params);
}
export function logClickDashboardRecommendedCardCta(params) {
  return _log(18983, 'ClickDashboardRecommendedCardCta', 'ClickDashboardRecommendedCardCtaWebJs', params);
}
export function logClickExternalLink(params) {
  return _log(17615, 'ClickExternalLink', 'ClickExternalLinkWebJs', params);
}
export function logClickGetStartedOnDemoModal() {
  return _log(22674, 'ClickGetStartedOnDemoModal', 'ClickGetStartedOnDemoModalWebJs');
}
export function logClickGetStartedReferralBanner(params) {
  return _log(18433, 'ClickGetStartedReferralBanner', 'ClickGetStartedReferralBannerWebJs', params);
}
export function logClickGoogleSignupOptions() {
  return _log(22934, 'ClickGoogleSignupOptions', 'ClickGoogleSignupOptionsWebJs');
}
export function logClickInAppMsg(params) {
  return _log(20484, 'ClickInAppMsg', 'ClickInAppMsgWebJs', params);
}
export function logClickMyLibraryLoadMore(params) {
  return _log(18985, 'ClickMyLibraryLoadMore', 'ClickMyLibraryLoadMoreWebJs', params);
}
export function logClickNextOnClassicToNextOnboarding(params) {
  return _log(19580, 'ClickNextOnClassicToNextOnboarding', 'ClickNextOnClassicToNextOnboardingWebJs', params);
}
export function logClickNextOnLibraryBasket() {
  return _log(17933, 'ClickNextOnLibraryBasket', 'ClickNextOnLibraryBasketWebJs');
}
export function logClickNotificationCenterMessage(params) {
  return _log(22390, 'ClickNotificationCenterMessage', 'ClickNotificationCenterMessageWebJs', params);
}
export function logClickPreziLogo() {
  return _log(15231, 'ClickPreziLogo', 'ClickPreziLogoWebJs');
}
export function logClickReusablePreziCard(params) {
  return _log(15622, 'ClickReusablePreziCard', 'ClickReusablePreziCardWebJs', params);
}
export function logClickSignupOnDemoModal() {
  return _log(22672, 'ClickSignupOnDemoModal', 'ClickSignupOnDemoModalWebJs');
}
export function logClickTemplateCard(params) {
  return _log(15620, 'ClickTemplateCard', 'ClickTemplateCardWebJs', params);
}
export function logClickVideoTag(params) {
  return _log(15698, 'ClickVideoTag', 'ClickVideoTagWebJs', params);
}
export function logClickVideoWelcomeClose() {
  return _log(15762, 'ClickVideoWelcomeClose', 'ClickVideoWelcomeCloseWebJs');
}
export function logClickVideoWelcomeLearnMore() {
  return _log(15764, 'ClickVideoWelcomeLearnMore', 'ClickVideoWelcomeLearnMoreWebJs');
}
export function logClickVideoWelcomeTerms() {
  return _log(15766, 'ClickVideoWelcomeTerms', 'ClickVideoWelcomeTermsWebJs');
}
export function logClickedContinueWithSso(params) {
  return _log(23695, 'ClickedContinueWithSso', 'ClickedContinueWithSsoWebJs', params);
}
export function logClickedCreateBrandkitCta(params) {
  return _log(23894, 'ClickedCreateBrandkitCta', 'ClickedCreateBrandkitCtaWebJs', params);
}
export function logCloseClassicToNextModal() {
  return _log(19582, 'CloseClassicToNextModal', 'CloseClassicToNextModalWebJs');
}
export function logCloseCollaboratorsDialog(params) {
  return _log(17292, 'CloseCollaboratorsDialog', 'CloseCollaboratorsDialogWebJs', params);
}
export function logCloseCreateAnalyticsLinkDialog() {
  return _log(14104, 'CloseCreateAnalyticsLinkDialog', 'CloseCreateAnalyticsLinkDialogWebJs');
}
export function logCloseDemoModal() {
  return _log(22732, 'CloseDemoModal', 'CloseDemoModalWebJs');
}
export function logCloseDesignExportToVideoDialog(params) {
  return _log(22199, 'CloseDesignExportToVideoDialog', 'CloseDesignExportToVideoDialogWebJs', params);
}
export function logCloseDownloadVideoAppPopup() {
  return _log(16711, 'CloseDownloadVideoAppPopup', 'CloseDownloadVideoAppPopupWebJs');
}
export function logCloseExportPdfDialog(params) {
  return _log(23162, 'CloseExportPdfDialog', 'CloseExportPdfDialogWebJs', params);
}
export function logCloseExportPptDialog(params) {
  return _log(14110, 'CloseExportPptDialog', 'CloseExportPptDialogWebJs', params);
}
export function logCloseFolderCollaboratorsDialog(params) {
  return _log(15239, 'CloseFolderCollaboratorsDialog', 'CloseFolderCollaboratorsDialogWebJs', params);
}
export function logCloseFrameCollection(params) {
  return _log(17939, 'CloseFrameCollection', 'CloseFrameCollectionWebJs', params);
}
export function logCloseMyLibraryExpandedView(params) {
  return _log(17069, 'CloseMyLibraryExpandedView', 'CloseMyLibraryExpandedViewWebJs', params);
}
export function logCloseNotificationCenter(params) {
  return _log(21980, 'CloseNotificationCenter', 'CloseNotificationCenterWebJs', params);
}
export function logCloseNotification() {
  return _log(14883, 'CloseNotification', 'CloseNotificationWebJs');
}
export function logClosePaywallDialog() {
  return _log(14984, 'ClosePaywallDialog', 'ClosePaywallDialogWebJs');
}
export function logClosePreziPlayerDialog(params) {
  return _log(17975, 'ClosePreziPlayerDialog', 'ClosePreziPlayerDialogWebJs', params);
}
export function logClosePrivacyDialog(params) {
  return _log(24024, 'ClosePrivacyDialog', 'ClosePrivacyDialogWebJs', params);
}
export function logCloseReferralInviteLearnMore(params) {
  return _log(18721, 'CloseReferralInviteLearnMore', 'CloseReferralInviteLearnMoreWebJs', params);
}
export function logCloseSupportWidget(params) {
  return _log(18690, 'CloseSupportWidget', 'CloseSupportWidgetWebJs', params);
}
export function logCloseUnsupportedBrowserEmailConfirmation() {
  return _log(17727, 'CloseUnsupportedBrowserEmailConfirmation', 'CloseUnsupportedBrowserEmailConfirmationWebJs');
}
export function logCloseUnsupportedBrowserModal() {
  return _log(17729, 'CloseUnsupportedBrowserModal', 'CloseUnsupportedBrowserModalWebJs');
}
export function logClosedDemoModal() {
  return _log(22668, 'ClosedDemoModal', 'ClosedDemoModalWebJs');
}
export function logConfirmExportDesignToVideo(params) {
  return _log(22214, 'ConfirmExportDesignToVideo', 'ConfirmExportDesignToVideoWebJs', params);
}
export function logConfirmSamlActivation(params) {
  return _log(16453, 'ConfirmSamlActivation', 'ConfirmSamlActivationWebJs', params);
}
export function logConfirmSamlDeactivation(params) {
  return _log(16455, 'ConfirmSamlDeactivation', 'ConfirmSamlDeactivationWebJs', params);
}
export function logConfirmTeamMemberRemovalFailed(params) {
  return _log(14135, 'ConfirmTeamMemberRemovalFailed', 'ConfirmTeamMemberRemovalFailedWebJs', params);
}
export function logConfirmTeamMemberRemoval(params) {
  return _log(14133, 'ConfirmTeamMemberRemoval', 'ConfirmTeamMemberRemovalWebJs', params);
}
export function logConfirmedClassicToNextCopy(params) {
  return _log(19596, 'ConfirmedClassicToNextCopy', 'ConfirmedClassicToNextCopyWebJs', params);
}
export function logConfirmedPayment(params) {
  return _log(15835, 'ConfirmedPayment', 'ConfirmedPaymentWebJs', params);
}
export function logConnectSocialAccount(params) {
  return _log(21943, 'ConnectSocialAccount', 'ConnectSocialAccountWebJs', params);
}
export function logContactSales(params) {
  return _log(23938, 'ContactSales', 'ContactSalesWebJs', params);
}
export function logContactSupport() {
  return _log(16718, 'ContactSupport', 'ContactSupportWebJs');
}
export function logContactTeamAdmin(params) {
  return _log(23936, 'ContactTeamAdmin', 'ContactTeamAdminWebJs', params);
}
export function logContinueDesignToVideoOnboardingPopup() {
  return _log(20519, 'ContinueDesignToVideoOnboardingPopup', 'ContinueDesignToVideoOnboardingPopupWebJs');
}
export function logContinueWithNewIntegrationAccount(params) {
  return _log(23304, 'ContinueWithNewIntegrationAccount', 'ContinueWithNewIntegrationAccountWebJs', params);
}
export function logCopiedPresentation(params) {
  return _log(15536, 'CopiedPresentation', 'CopiedPresentationWebJs', params);
}
export function logCopyClassicToNext(params) {
  return _log(17884, 'CopyClassicToNext', 'CopyClassicToNextWebJs', params);
}
export function logCopyDesign(params) {
  return _log(19531, 'CopyDesign', 'CopyDesignWebJs', params);
}
export function logCopyDocument(params) {
  return _log(23724, 'CopyDocument', 'CopyDocumentWebJs', params);
}
export function logCopyEmbedCodeToClipboard(params) {
  return _log(20817, 'CopyEmbedCodeToClipboard', 'CopyEmbedCodeToClipboardWebJs', params);
}
export function logCopyGram(params) {
  return _log(17310, 'CopyGram', 'CopyGramWebJs', params);
}
export function logCopyPresentation(params) {
  return _log(15124, 'CopyPresentation', 'CopyPresentationWebJs', params);
}
export function logCopyPublishLink(params) {
  return _log(17354, 'CopyPublishLink', 'CopyPublishLinkWebJs', params);
}
export function logCopyReferralInviteLink(params) {
  return _log(18356, 'CopyReferralInviteLink', 'CopyReferralInviteLinkWebJs', params);
}
export function logCopyViewLink(params) {
  return _log(24061, 'CopyViewLink', 'CopyViewLinkWebJs', params);
}
export function logCreateNewBrandkit() {
  return _log(22496, 'CreateNewBrandkit', 'CreateNewBrandkitWebJs');
}
export function logCreateNewGramFromPpt(params) {
  return _log(17957, 'CreateNewGramFromPpt', 'CreateNewGramFromPptWebJs', params);
}
export function logCreateNewGramFromScratch(params) {
  return _log(23533, 'CreateNewGramFromScratch', 'CreateNewGramFromScratchWebJs', params);
}
export function logCreateNewGramFromTemplate(params) {
  return _log(17814, 'CreateNewGramFromTemplate', 'CreateNewGramFromTemplateWebJs', params);
}
export function logCreateNewGram(params) {
  return _log(23531, 'CreateNewGram', 'CreateNewGramWebJs', params);
}
export function logCreateNewPresentationFromPpt(params) {
  return _log(20628, 'CreateNewPresentationFromPpt', 'CreateNewPresentationFromPptWebJs', params);
}
export function logCreateNewPresentationFromScratch(params) {
  return _log(20630, 'CreateNewPresentationFromScratch', 'CreateNewPresentationFromScratchWebJs', params);
}
export function logCreateNewPresentationFromTemplate(params) {
  return _log(24572, 'CreateNewPresentationFromTemplate', 'CreateNewPresentationFromTemplateWebJs', params);
}
export function logCreateNewPresentation(params) {
  return _log(20626, 'CreateNewPresentation', 'CreateNewPresentationWebJs', params);
}
export function logCreateNewPresentationWithCustomBranding() {
  return _log(15005, 'CreateNewPresentationWithCustomBranding', 'CreateNewPresentationWithCustomBrandingWebJs');
}
export function logCreateNewVideoFromScratch(params) {
  return _log(17878, 'CreateNewVideoFromScratch', 'CreateNewVideoFromScratchWebJs', params);
}
export function logCreateNewVideoFromTemplate(params) {
  return _log(20586, 'CreateNewVideoFromTemplate', 'CreateNewVideoFromTemplateWebJs', params);
}
export function logCreateNewVideo(params) {
  return _log(20557, 'CreateNewVideo', 'CreateNewVideoWebJs', params);
}
export function logCreatedNewFolder(params) {
  return _log(17851, 'CreatedNewFolder', 'CreatedNewFolderWebJs', params);
}
export function logCreatedViewLink(params) {
  return _log(17204, 'CreatedViewLink', 'CreatedViewLinkWebJs', params);
}
export function logDeactivatedViewLink(params) {
  return _log(17173, 'DeactivatedViewLink', 'DeactivatedViewLinkWebJs', params);
}
export function logDeleteAccount() {
  return _log(14150, 'DeleteAccount', 'DeleteAccountWebJs');
}
export function logDeleteFolder(params) {
  return _log(17858, 'DeleteFolder', 'DeleteFolderWebJs', params);
}
export function logDeleteGram(params) {
  return _log(17314, 'DeleteGram', 'DeleteGramWebJs', params);
}
export function logDeletedBrandkit() {
  return _log(18872, 'DeletedBrandkit', 'DeletedBrandkitWebJs');
}
export function logDeletedIntegration(params) {
  return _log(24383, 'DeletedIntegration', 'DeletedIntegrationWebJs', params);
}
export function logDisableSamlSwitch(params) {
  return _log(16445, 'DisableSamlSwitch', 'DisableSamlSwitchWebJs', params);
}
export function logDisabledSaml(params) {
  return _log(16460, 'DisabledSaml', 'DisabledSamlWebJs', params);
}
export function logDisconnectSocialAccount(params) {
  return _log(21945, 'DisconnectSocialAccount', 'DisconnectSocialAccountWebJs', params);
}
export function logDismissInAppMsg(params) {
  return _log(20486, 'DismissInAppMsg', 'DismissInAppMsgWebJs', params);
}
export function logDisplayedInAppMsg(params) {
  return _log(20488, 'DisplayedInAppMsg', 'DisplayedInAppMsgWebJs', params);
}
export function logDisplayedInvoices(params) {
  return _log(17769, 'DisplayedInvoices', 'DisplayedInvoicesWebJs', params);
}
export function logDisplayedNotificationCenterMessage(params) {
  return _log(22172, 'DisplayedNotificationCenterMessage', 'DisplayedNotificationCenterMessageWebJs', params);
}
export function logDisplayedNotificationCenterOnboarding() {
  return _log(22224, 'DisplayedNotificationCenterOnboarding', 'DisplayedNotificationCenterOnboardingWebJs');
}
export function logDisplayedPaymentMethodChallenge() {
  return _log(23095, 'DisplayedPaymentMethodChallenge', 'DisplayedPaymentMethodChallengeWebJs');
}
export function logDisplayedPaymentMethodError(params) {
  return _log(22651, 'DisplayedPaymentMethodError', 'DisplayedPaymentMethodErrorWebJs', params);
}
export function logDownloadAnalyticsCsv(params) {
  return _log(14157, 'DownloadAnalyticsCsv', 'DownloadAnalyticsCsvWebJs', params);
}
export function logDownloadAppStarted() {
  return _log(15801, 'DownloadAppStarted', 'DownloadAppStartedWebJs');
}
export function logDownloadApp() {
  return _log(15797, 'DownloadApp', 'DownloadAppWebJs');
}
export function logDownloadChrome() {
  return _log(24075, 'DownloadChrome', 'DownloadChromeWebJs');
}
export function logDownloadDesignAnalyticsCsv(params) {
  return _log(24469, 'DownloadDesignAnalyticsCsv', 'DownloadDesignAnalyticsCsvWebJs', params);
}
export function logDownloadFramesAsImages(params) {
  return _log(17973, 'DownloadFramesAsImages', 'DownloadFramesAsImagesWebJs', params);
}
export function logDownloadGramProject(params) {
  return _log(21005, 'DownloadGramProject', 'DownloadGramProjectWebJs', params);
}
export function logDownloadInvoice(params) {
  return _log(17767, 'DownloadInvoice', 'DownloadInvoiceWebJs', params);
}
export function logDownloadMobileApp() {
  return _log(14164, 'DownloadMobileApp', 'DownloadMobileAppWebJs');
}
export function logDownloadMyLibraryItemAsImage(params) {
  return _log(17079, 'DownloadMyLibraryItemAsImage', 'DownloadMyLibraryItemAsImageWebJs', params);
}
export function logDownloadTeamPresentationsList(params) {
  return _log(14169, 'DownloadTeamPresentationsList', 'DownloadTeamPresentationsListWebJs', params);
}
export function logDownloadVideoApp(params) {
  return _log(22659, 'DownloadVideoApp', 'DownloadVideoAppWebJs', params);
}
export function logEditBrandkit() {
  return _log(22494, 'EditBrandkit', 'EditBrandkitWebJs');
}
export function logEditCookieSettings() {
  return _log(24503, 'EditCookieSettings', 'EditCookieSettingsWebJs');
}
export function logEnableSamlSwitch(params) {
  return _log(16447, 'EnableSamlSwitch', 'EnableSamlSwitchWebJs', params);
}
export function logEnabledSaml(params) {
  return _log(16463, 'EnabledSaml', 'EnabledSamlWebJs', params);
}
export function logEnteredAbTestExperience(params) {
  return _log(22254, 'EnteredAbTestExperience', 'EnteredAbTestExperienceWebJs', params);
}
export function logEnteredFalseCredentials(params) {
  return _log(24381, 'EnteredFalseCredentials', 'EnteredFalseCredentialsWebJs', params);
}
export function logExploreGram() {
  return _log(17316, 'ExploreGram', 'ExploreGramWebJs');
}
export function logExplorePresentations(params) {
  return _log(17979, 'ExplorePresentations', 'ExplorePresentationsWebJs', params);
}
export function logExploreVideoTemplates() {
  return _log(20588, 'ExploreVideoTemplates', 'ExploreVideoTemplatesWebJs');
}
export function logExportDesignToVideo(params) {
  return _log(20517, 'ExportDesignToVideo', 'ExportDesignToVideoWebJs', params);
}
export function logExportPdf(params) {
  return _log(23156, 'ExportPdf', 'ExportPdfWebJs', params);
}
export function logExportPpt(params) {
  return _log(14186, 'ExportPpt', 'ExportPptWebJs', params);
}
export function logExtendTrial(params) {
  return _log(24592, 'ExtendTrial', 'ExtendTrialWebJs', params);
}
export function logFailedIframeLoad() {
  return _log(15140, 'FailedIframeLoad', 'FailedIframeLoadWebJs');
}
export function logFailedPaymentBuyNow(params) {
  return _log(14941, 'FailedPaymentBuyNow', 'FailedPaymentBuyNowWebJs', params);
}
export function logFailedPaymentSubmit(params) {
  return _log(15142, 'FailedPaymentSubmit', 'FailedPaymentSubmitWebJs', params);
}
export function logFailedSamlLogin(params) {
  return _log(16976, 'FailedSamlLogin', 'FailedSamlLoginWebJs', params);
}
export function logFailedToCopyPresentation(params) {
  return _log(15546, 'FailedToCopyPresentation', 'FailedToCopyPresentationWebJs', params);
}
export function logFilterContent(params) {
  return _log(23735, 'FilterContent', 'FilterContentWebJs', params);
}
export function logFilterForAllProjects(params) {
  return _log(17882, 'FilterForAllProjects', 'FilterForAllProjectsWebJs', params);
}
export function logFilterForOwnership(params) {
  return _log(17848, 'FilterForOwnership', 'FilterForOwnershipWebJs', params);
}
export function logFilterPresentations(params) {
  return _log(14190, 'FilterPresentations', 'FilterPresentationsWebJs', params);
}
export function logFilterWelcomeTemplates(params) {
  return _log(20606, 'FilterWelcomeTemplates', 'FilterWelcomeTemplatesWebJs', params);
}
export function logFinishedExportingPdf(params) {
  return _log(23150, 'FinishedExportingPdf', 'FinishedExportingPdfWebJs', params);
}
export function logFirstUseCase(params) {
  return _log(23322, 'FirstUseCase', 'FirstUseCaseWebJs', params);
}
export function logFlagContent(params) {
  return _log(17977, 'FlagContent', 'FlagContentWebJs', params);
}
export function logFramesUploaded(params) {
  return _log(18087, 'FramesUploaded', 'FramesUploadedWebJs', params);
}
export function logInviteNewTeamMember(params) {
  return _log(23362, 'InviteNewTeamMember', 'InviteNewTeamMemberWebJs', params);
}
export function logInvitedTeamMember(params) {
  return _log(14210, 'InvitedTeamMember', 'InvitedTeamMemberWebJs', params);
}
export function logLearnMoreAboutViewToVideo(params) {
  return _log(18085, 'LearnMoreAboutViewToVideo', 'LearnMoreAboutViewToVideoWebJs', params);
}
export function logLeaveFullScreen(params) {
  return _log(17402, 'LeaveFullScreen', 'LeaveFullScreenWebJs', params);
}
export function logLikePresentationPage(params) {
  return _log(15839, 'LikePresentationPage', 'LikePresentationPageWebJs', params);
}
export function logLikePublishPage(params) {
  return _log(17360, 'LikePublishPage', 'LikePublishPageWebJs', params);
}
export function logLoadAllViewLinks(params) {
  return _log(15284, 'LoadAllViewLinks', 'LoadAllViewLinksWebJs', params);
}
export function logLoadDetailedView(params) {
  return _log(14595, 'LoadDetailedView', 'LoadDetailedViewWebJs', params);
}
export function logLoadEditor(params) {
  return _log(14548, 'LoadEditor', 'LoadEditorWebJs', params);
}
export function logLoadPreziCard(params) {
  return _log(15518, 'LoadPreziCard', 'LoadPreziCardWebJs', params);
}
export function logLoadPrezi(params) {
  return _log(14283, 'LoadPrezi', 'LoadPreziWebJs', params);
}
export function logLoadPublicViewPage(params) {
  return _log(13881, 'LoadPublicViewPage', 'LoadPublicViewPageWebJs', params);
}
export function logLoadedAccountSettings() {
  return _log(14232, 'LoadedAccountSettings', 'LoadedAccountSettingsWebJs');
}
export function logLoadedBillingPage(params) {
  return _log(18141, 'LoadedBillingPage', 'LoadedBillingPageWebJs', params);
}
export function logLoadedBlogPage() {
  return _log(15793, 'LoadedBlogPage', 'LoadedBlogPageWebJs');
}
export function logLoadedBrandkit(params) {
  return _log(18876, 'LoadedBrandkit', 'LoadedBrandkitWebJs', params);
}
export function logLoadedBrowserNotificationsDialog() {
  return _log(14887, 'LoadedBrowserNotificationsDialog', 'LoadedBrowserNotificationsDialogWebJs');
}
export function logLoadedBuyNowPage() {
  return _log(14909, 'LoadedBuyNowPage', 'LoadedBuyNowPageWebJs');
}
export function logLoadedContactSalesCalendar(params) {
  return _log(24055, 'LoadedContactSalesCalendar', 'LoadedContactSalesCalendarWebJs', params);
}
export function logLoadedContactSalesForm(params) {
  return _log(23236, 'LoadedContactSalesForm', 'LoadedContactSalesFormWebJs', params);
}
export function logLoadedCookieBanner() {
  return _log(24505, 'LoadedCookieBanner', 'LoadedCookieBannerWebJs');
}
export function logLoadedCopyFailedDialog(params) {
  return _log(15028, 'LoadedCopyFailedDialog', 'LoadedCopyFailedDialogWebJs', params);
}
export function logLoadedCopyInProgressDialog(params) {
  return _log(15034, 'LoadedCopyInProgressDialog', 'LoadedCopyInProgressDialogWebJs', params);
}
export function logLoadedCreateTeam(params) {
  return _log(22695, 'LoadedCreateTeam', 'LoadedCreateTeamWebJs', params);
}
export function logLoadedDashboard(params) {
  return _log(17251, 'LoadedDashboard', 'LoadedDashboardWebJs', params);
}
export function logLoadedDetailedView(params) {
  return _log(14237, 'LoadedDetailedView', 'LoadedDetailedViewWebJs', params);
}
export function logLoadedDownloadAppDialog() {
  return _log(14245, 'LoadedDownloadAppDialog', 'LoadedDownloadAppDialogWebJs');
}
export function logLoadedDownloadVideoAppPopup() {
  return _log(22633, 'LoadedDownloadVideoAppPopup', 'LoadedDownloadVideoAppPopupWebJs');
}
export function logLoadedDownloadVideoAppWelcomeScreen() {
  return _log(20614, 'LoadedDownloadVideoAppWelcomeScreen', 'LoadedDownloadVideoAppWelcomeScreenWebJs');
}
export function logLoadedEditor(params) {
  return _log(18993, 'LoadedEditor', 'LoadedEditorWebJs', params);
}
export function logLoadedFolder(params) {
  return _log(14257, 'LoadedFolder', 'LoadedFolderWebJs', params);
}
export function logLoadedFrameCollection(params) {
  return _log(17941, 'LoadedFrameCollection', 'LoadedFrameCollectionWebJs', params);
}
export function logLoadedIntegrationArea(params) {
  return _log(24123, 'LoadedIntegrationArea', 'LoadedIntegrationAreaWebJs', params);
}
export function logLoadedInviteTeamMember(params) {
  return _log(22699, 'LoadedInviteTeamMember', 'LoadedInviteTeamMemberWebJs', params);
}
export function logLoadedLivePreziFollowPage(params) {
  return _log(14262, 'LoadedLivePreziFollowPage', 'LoadedLivePreziFollowPageWebJs', params);
}
export function logLoadedMyLibrary(params) {
  return _log(17698, 'LoadedMyLibrary', 'LoadedMyLibraryWebJs', params);
}
export function logLoadedNotificationCenterFirstPage(params) {
  return _log(22174, 'LoadedNotificationCenterFirstPage', 'LoadedNotificationCenterFirstPageWebJs', params);
}
export function logLoadedNotification(params) {
  return _log(14889, 'LoadedNotification', 'LoadedNotificationWebJs', params);
}
export function logLoadedPaymentMethodPage() {
  return _log(22355, 'LoadedPaymentMethodPage', 'LoadedPaymentMethodPageWebJs');
}
export function logLoadedPaywallCta(params) {
  return _log(23630, 'LoadedPaywallCta', 'LoadedPaywallCtaWebJs', params);
}
export function logLoadedPaywallDialog(params) {
  return _log(24549, 'LoadedPaywallDialog', 'LoadedPaywallDialogWebJs', params);
}
export function logLoadedPresentationPicker(params) {
  return _log(18419, 'LoadedPresentationPicker', 'LoadedPresentationPickerWebJs', params);
}
export function logLoadedPreziCard(params) {
  return _log(15296, 'LoadedPreziCard', 'LoadedPreziCardWebJs', params);
}
export function logLoadedPrezi(params) {
  return _log(15294, 'LoadedPrezi', 'LoadedPreziWebJs', params);
}
export function logLoadedProductSelectorDialog() {
  return _log(23290, 'LoadedProductSelectorDialog', 'LoadedProductSelectorDialogWebJs');
}
export function logLoadedPublicPublishPage(params) {
  return _log(20262, 'LoadedPublicPublishPage', 'LoadedPublicPublishPageWebJs', params);
}
export function logLoadedPublicViewPage(params) {
  return _log(15298, 'LoadedPublicViewPage', 'LoadedPublicViewPageWebJs', params);
}
export function logLoadedRecordPresentationWelcomeScreen() {
  return _log(20616, 'LoadedRecordPresentationWelcomeScreen', 'LoadedRecordPresentationWelcomeScreenWebJs');
}
export function logLoadedReferralDashboard(params) {
  return _log(18348, 'LoadedReferralDashboard', 'LoadedReferralDashboardWebJs', params);
}
export function logLoadedReferralInviteBanner(params) {
  return _log(18406, 'LoadedReferralInviteBanner', 'LoadedReferralInviteBannerWebJs', params);
}
export function logLoadedReferralInviteButton(params) {
  return _log(18981, 'LoadedReferralInviteButton', 'LoadedReferralInviteButtonWebJs', params);
}
export function logLoadedReferralLanding(params) {
  return _log(18674, 'LoadedReferralLanding', 'LoadedReferralLandingWebJs', params);
}
export function logLoadedReferralLeaderboard(params) {
  return _log(18370, 'LoadedReferralLeaderboard', 'LoadedReferralLeaderboardWebJs', params);
}
export function logLoadedReferralSignup(params) {
  return _log(18667, 'LoadedReferralSignup', 'LoadedReferralSignupWebJs', params);
}
export function logLoadedSegmentationDialog() {
  return _log(15300, 'LoadedSegmentationDialog', 'LoadedSegmentationDialogWebJs');
}
export function logLoadedSignupFlow(params) {
  return _log(22655, 'LoadedSignupFlow', 'LoadedSignupFlowWebJs', params);
}
export function logLoadedSubSegmentationDialog() {
  return _log(20582, 'LoadedSubSegmentationDialog', 'LoadedSubSegmentationDialogWebJs');
}
export function logLoadedTeamInvitationDialog(params) {
  return _log(15302, 'LoadedTeamInvitationDialog', 'LoadedTeamInvitationDialogWebJs', params);
}
export function logLoadedTeamMemberList(params) {
  return _log(14272, 'LoadedTeamMemberList', 'LoadedTeamMemberListWebJs', params);
}
export function logLoadedTeamMemberSignupPage() {
  return _log(15304, 'LoadedTeamMemberSignupPage', 'LoadedTeamMemberSignupPageWebJs');
}
export function logLoadedTemplateList(params) {
  return _log(16389, 'LoadedTemplateList', 'LoadedTemplateListWebJs', params);
}
export function logLoadedTranscript(params) {
  return _log(15308, 'LoadedTranscript', 'LoadedTranscriptWebJs', params);
}
export function logLoadedViewerIdentificationForm(params) {
  return _log(15515, 'LoadedViewerIdentificationForm', 'LoadedViewerIdentificationFormWebJs', params);
}
export function logLoadedViewer(params) {
  return _log(15316, 'LoadedViewer', 'LoadedViewerWebJs', params);
}
export function logLoadedWelcomeDeliverySelection() {
  return _log(20612, 'LoadedWelcomeDeliverySelection', 'LoadedWelcomeDeliverySelectionWebJs');
}
export function logLoadedWelcomePresentContentOptions() {
  return _log(20618, 'LoadedWelcomePresentContentOptions', 'LoadedWelcomePresentContentOptionsWebJs');
}
export function logLoadedWelcomeTemplateSelector() {
  return _log(20596, 'LoadedWelcomeTemplateSelector', 'LoadedWelcomeTemplateSelectorWebJs');
}
export function logLoadedWelcomeVideoSelector() {
  return _log(20584, 'LoadedWelcomeVideoSelector', 'LoadedWelcomeVideoSelectorWebJs');
}
export function logLoadedWowDownloadPage() {
  return _log(22085, 'LoadedWowDownloadPage', 'LoadedWowDownloadPageWebJs');
}
export function logLogOut(params) {
  return _log(17872, 'LogOut', 'LogOutWebJs', params);
}
export function logLoggedIn(params) {
  return _log(23221, 'LoggedIn', 'LoggedInWebJs', params);
}
export function logLogin(params) {
  return _log(21954, 'Login', 'LoginWebJs', params);
}
export function logMakeAdmin(params) {
  return _log(14296, 'MakeAdmin', 'MakeAdminWebJs', params);
}
export function logMarkAllNotificationsAsRead() {
  return _log(21984, 'MarkAllNotificationsAsRead', 'MarkAllNotificationsAsReadWebJs');
}
export function logMarkNotificationAsRead(params) {
  return _log(22392, 'MarkNotificationAsRead', 'MarkNotificationAsReadWebJs', params);
}
export function logMismatchedIntegrationUserAssociation(params) {
  return _log(23309, 'MismatchedIntegrationUserAssociation', 'MismatchedIntegrationUserAssociationWebJs', params);
}
export function logNavigatePresentationStructureDesignVideoPreview(params) {
  return _log(22209, 'NavigatePresentationStructureDesignVideoPreview', 'NavigatePresentationStructureDesignVideoPreviewWebJs', params);
}
export function logNavigatePrezi(params) {
  return _log(20823, 'NavigatePrezi', 'NavigatePreziWebJs', params);
}
export function logNavigateUnifiedProjectPicker(params) {
  return _log(17874, 'NavigateUnifiedProjectPicker', 'NavigateUnifiedProjectPickerWebJs', params);
}
export function logNewSession() {
  return _log(15331, 'NewSession', 'NewSessionWebJs');
}
export function logOpenAccountSettings(params) {
  return _log(17839, 'OpenAccountSettings', 'OpenAccountSettingsWebJs', params);
}
export function logOpenAdminConsole(params) {
  return _log(17846, 'OpenAdminConsole', 'OpenAdminConsoleWebJs', params);
}
export function logOpenAllViewLinks(params) {
  return _log(15347, 'OpenAllViewLinks', 'OpenAllViewLinksWebJs', params);
}
export function logOpenAnalyticsDetailsPage(params) {
  return _log(15353, 'OpenAnalyticsDetailsPage', 'OpenAnalyticsDetailsPageWebJs', params);
}
export function logOpenAnalyticsLinkSettings(params) {
  return _log(15355, 'OpenAnalyticsLinkSettings', 'OpenAnalyticsLinkSettingsWebJs', params);
}
export function logOpenAnalytics() {
  return _log(15349, 'OpenAnalytics', 'OpenAnalyticsWebJs');
}
export function logOpenArticle(params) {
  return _log(16422, 'OpenArticle', 'OpenArticleWebJs', params);
}
export function logOpenCameraSelector() {
  return _log(22734, 'OpenCameraSelector', 'OpenCameraSelectorWebJs');
}
export function logOpenCollaboratorsDialog(params) {
  return _log(14605, 'OpenCollaboratorsDialog', 'OpenCollaboratorsDialogWebJs', params);
}
export function logOpenCompanyLeaderboard(params) {
  return _log(14323, 'OpenCompanyLeaderboard', 'OpenCompanyLeaderboardWebJs', params);
}
export function logOpenCreateAnalyticsLinkDialog() {
  return _log(14325, 'OpenCreateAnalyticsLinkDialog', 'OpenCreateAnalyticsLinkDialogWebJs');
}
export function logOpenCreateNewDropdown(params) {
  return _log(17868, 'OpenCreateNewDropdown', 'OpenCreateNewDropdownWebJs', params);
}
export function logOpenCreateViewLinkDialog(params) {
  return _log(17210, 'OpenCreateViewLinkDialog', 'OpenCreateViewLinkDialogWebJs', params);
}
export function logOpenDashboardCardContextMenu(params) {
  return _log(18987, 'OpenDashboardCardContextMenu', 'OpenDashboardCardContextMenuWebJs', params);
}
export function logOpenDashboard(params) {
  return _log(20832, 'OpenDashboard', 'OpenDashboardWebJs', params);
}
export function logOpenDeleteFolderDialog(params) {
  return _log(14328, 'OpenDeleteFolderDialog', 'OpenDeleteFolderDialogWebJs', params);
}
export function logOpenDeletePresentationDialog(params) {
  return _log(14655, 'OpenDeletePresentationDialog', 'OpenDeletePresentationDialogWebJs', params);
}
export function logOpenDesignAnalyticsDatePicker(params) {
  return _log(24473, 'OpenDesignAnalyticsDatePicker', 'OpenDesignAnalyticsDatePickerWebJs', params);
}
export function logOpenDesignAnalyticsDetailsPage(params) {
  return _log(24465, 'OpenDesignAnalyticsDetailsPage', 'OpenDesignAnalyticsDetailsPageWebJs', params);
}
export function logOpenDesignAnalytics() {
  return _log(24463, 'OpenDesignAnalytics', 'OpenDesignAnalyticsWebJs');
}
export function logOpenDetailedView(params) {
  return _log(15524, 'OpenDetailedView', 'OpenDetailedViewWebJs', params);
}
export function logOpenDocumentDetails(params) {
  return _log(23737, 'OpenDocumentDetails', 'OpenDocumentDetailsWebJs', params);
}
export function logOpenDocumentToEdit(params) {
  return _log(23739, 'OpenDocumentToEdit', 'OpenDocumentToEditWebJs', params);
}
export function logOpenDocumentToView(params) {
  return _log(23741, 'OpenDocumentToView', 'OpenDocumentToViewWebJs', params);
}
export function logOpenDownloadCenter(params) {
  return _log(23648, 'OpenDownloadCenter', 'OpenDownloadCenterWebJs', params);
}
export function logOpenDownloadPresentationDialog(params) {
  return _log(23189, 'OpenDownloadPresentationDialog', 'OpenDownloadPresentationDialogWebJs', params);
}
export function logOpenDownloadPreziAppsModal(params) {
  return _log(22736, 'OpenDownloadPreziAppsModal', 'OpenDownloadPreziAppsModalWebJs', params);
}
export function logOpenEditor(params) {
  return _log(14638, 'OpenEditor', 'OpenEditorWebJs', params);
}
export function logOpenEmbedShare(params) {
  return _log(24050, 'OpenEmbedShare', 'OpenEmbedShareWebJs', params);
}
export function logOpenExpandedViewSource(params) {
  return _log(23745, 'OpenExpandedViewSource', 'OpenExpandedViewSourceWebJs', params);
}
export function logOpenExportPdfDialog(params) {
  return _log(23140, 'OpenExportPdfDialog', 'OpenExportPdfDialogWebJs', params);
}
export function logOpenExportPptDialog(params) {
  return _log(14335, 'OpenExportPptDialog', 'OpenExportPptDialogWebJs', params);
}
export function logOpenExportedPdf(params) {
  return _log(23146, 'OpenExportedPdf', 'OpenExportedPdfWebJs', params);
}
export function logOpenFolderCollaboratorsDialog(params) {
  return _log(15357, 'OpenFolderCollaboratorsDialog', 'OpenFolderCollaboratorsDialogWebJs', params);
}
export function logOpenFolderListForPresentation(params) {
  return _log(15363, 'OpenFolderListForPresentation', 'OpenFolderListForPresentationWebJs', params);
}
export function logOpenFolder(params) {
  return _log(17865, 'OpenFolder', 'OpenFolderWebJs', params);
}
export function logOpenFrameCollection(params) {
  return _log(17943, 'OpenFrameCollection', 'OpenFrameCollectionWebJs', params);
}
export function logOpenFrame(params) {
  return _log(17971, 'OpenFrame', 'OpenFrameWebJs', params);
}
export function logOpenHelpCenterPage() {
  return _log(18332, 'OpenHelpCenterPage', 'OpenHelpCenterPageWebJs');
}
export function logOpenHelpMenu(params) {
  return _log(17832, 'OpenHelpMenu', 'OpenHelpMenuWebJs', params);
}
export function logOpenHyperlink(params) {
  return _log(24274, 'OpenHyperlink', 'OpenHyperlinkWebJs', params);
}
export function logOpenIntercom() {
  return _log(14349, 'OpenIntercom', 'OpenIntercomWebJs');
}
export function logOpenInvoices(params) {
  return _log(17765, 'OpenInvoices', 'OpenInvoicesWebJs', params);
}
export function logOpenKnowledgeBase() {
  return _log(14351, 'OpenKnowledgeBase', 'OpenKnowledgeBaseWebJs');
}
export function logOpenLanguageChooser() {
  return _log(14357, 'OpenLanguageChooser', 'OpenLanguageChooserWebJs');
}
export function logOpenLearnMoreBrandkit() {
  return _log(18880, 'OpenLearnMoreBrandkit', 'OpenLearnMoreBrandkitWebJs');
}
export function logOpenLearnPage() {
  return _log(15479, 'OpenLearnPage', 'OpenLearnPageWebJs');
}
export function logOpenLicenseSettings() {
  return _log(14359, 'OpenLicenseSettings', 'OpenLicenseSettingsWebJs');
}
export function logOpenLivePreziDialog(params) {
  return _log(14644, 'OpenLivePreziDialog', 'OpenLivePreziDialogWebJs', params);
}
export function logOpenMobileApp() {
  return _log(15390, 'OpenMobileApp', 'OpenMobileAppWebJs');
}
export function logOpenMobileAppsPage() {
  return _log(14361, 'OpenMobileAppsPage', 'OpenMobileAppsPageWebJs');
}
export function logOpenMyLibraryExpandedView(params) {
  return _log(17067, 'OpenMyLibraryExpandedView', 'OpenMyLibraryExpandedViewWebJs', params);
}
export function logOpenNotificationCenter(params) {
  return _log(21978, 'OpenNotificationCenter', 'OpenNotificationCenterWebJs', params);
}
export function logOpenNotification() {
  return _log(14891, 'OpenNotification', 'OpenNotificationWebJs');
}
export function logOpenOnboardingPreziCardMenu(params) {
  return _log(15165, 'OpenOnboardingPreziCardMenu', 'OpenOnboardingPreziCardMenuWebJs', params);
}
export function logOpenOnboardingPreziDetailedView(params) {
  return _log(15395, 'OpenOnboardingPreziDetailedView', 'OpenOnboardingPreziDetailedViewWebJs', params);
}
export function logOpenOnboardingPreziToEdit(params) {
  return _log(15171, 'OpenOnboardingPreziToEdit', 'OpenOnboardingPreziToEditWebJs', params);
}
export function logOpenOnboardingPreziToPresent(params) {
  return _log(15174, 'OpenOnboardingPreziToPresent', 'OpenOnboardingPreziToPresentWebJs', params);
}
export function logOpenOnboardingVideo(params) {
  return _log(15403, 'OpenOnboardingVideo', 'OpenOnboardingVideoWebJs', params);
}
export function logOpenPresentationCardMenu(params) {
  return _log(14376, 'OpenPresentationCardMenu', 'OpenPresentationCardMenuWebJs', params);
}
export function logOpenPresentationFilter() {
  return _log(14379, 'OpenPresentationFilter', 'OpenPresentationFilterWebJs');
}
export function logOpenPresenterView(params) {
  return _log(24160, 'OpenPresenterView', 'OpenPresenterViewWebJs', params);
}
export function logOpenPreziCard(params) {
  return _log(15000, 'OpenPreziCard', 'OpenPreziCardWebJs', params);
}
export function logOpenPreziCommunityPage() {
  return _log(18334, 'OpenPreziCommunityPage', 'OpenPreziCommunityPageWebJs');
}
export function logOpenPreziMarketplace() {
  return _log(18775, 'OpenPreziMarketplace', 'OpenPreziMarketplaceWebJs');
}
export function logOpenPreziRemote() {
  return _log(15414, 'OpenPreziRemote', 'OpenPreziRemoteWebJs');
}
export function logOpenPreziVideoCommunityPage() {
  return _log(18336, 'OpenPreziVideoCommunityPage', 'OpenPreziVideoCommunityPageWebJs');
}
export function logOpenPreziVideoDesktop(params) {
  return _log(22638, 'OpenPreziVideoDesktop', 'OpenPreziVideoDesktopWebJs', params);
}
export function logOpenPrivacyDialog(params) {
  return _log(24033, 'OpenPrivacyDialog', 'OpenPrivacyDialogWebJs', params);
}
export function logOpenProductSwitcher() {
  return _log(14385, 'OpenProductSwitcher', 'OpenProductSwitcherWebJs');
}
export function logOpenProfileSettings() {
  return _log(14388, 'OpenProfileSettings', 'OpenProfileSettingsWebJs');
}
export function logOpenReferralInviteLearnMore() {
  return _log(18350, 'OpenReferralInviteLearnMore', 'OpenReferralInviteLearnMoreWebJs');
}
export function logOpenReferralInvite() {
  return _log(18364, 'OpenReferralInvite', 'OpenReferralInviteWebJs');
}
export function logOpenSocialShare(params) {
  return _log(24068, 'OpenSocialShare', 'OpenSocialShareWebJs', params);
}
export function logOpenSupportPage() {
  return _log(14394, 'OpenSupportPage', 'OpenSupportPageWebJs');
}
export function logOpenSupportWidgetChat(params) {
  return _log(18682, 'OpenSupportWidgetChat', 'OpenSupportWidgetChatWebJs', params);
}
export function logOpenSupportWidgetMiniArticle(params) {
  return _log(18686, 'OpenSupportWidgetMiniArticle', 'OpenSupportWidgetMiniArticleWebJs', params);
}
export function logOpenSupportWidgetOriginalArticle(params) {
  return _log(18684, 'OpenSupportWidgetOriginalArticle', 'OpenSupportWidgetOriginalArticleWebJs', params);
}
export function logOpenSupportWidget(params) {
  return _log(18956, 'OpenSupportWidget', 'OpenSupportWidgetWebJs', params);
}
export function logOpenTeamMemberList(params) {
  return _log(23926, 'OpenTeamMemberList', 'OpenTeamMemberListWebJs', params);
}
export function logOpenTeamSettings(params) {
  return _log(14398, 'OpenTeamSettings', 'OpenTeamSettingsWebJs', params);
}
export function logOpenToComment(params) {
  return _log(15433, 'OpenToComment', 'OpenToCommentWebJs', params);
}
export function logOpenToPresent(params) {
  return _log(15441, 'OpenToPresent', 'OpenToPresentWebJs', params);
}
export function logOpenTrainingPage() {
  return _log(15489, 'OpenTrainingPage', 'OpenTrainingPageWebJs');
}
export function logOpenTranscript(params) {
  return _log(20280, 'OpenTranscript', 'OpenTranscriptWebJs', params);
}
export function logOpenUnsupportedBrowserModal() {
  return _log(17733, 'OpenUnsupportedBrowserModal', 'OpenUnsupportedBrowserModalWebJs');
}
export function logOpenViewLinkAnalytics(params) {
  return _log(15450, 'OpenViewLinkAnalytics', 'OpenViewLinkAnalyticsWebJs', params);
}
export function logOpenViewLinkDialog(params) {
  return _log(17190, 'OpenViewLinkDialog', 'OpenViewLinkDialogWebJs', params);
}
export function logOpenWhatsNewPage() {
  return _log(18338, 'OpenWhatsNewPage', 'OpenWhatsNewPageWebJs');
}
export function logOpenWholeCollection(params) {
  return _log(17880, 'OpenWholeCollection', 'OpenWholeCollectionWebJs', params);
}
export function logOpenWorkshopAndTraining() {
  return _log(14413, 'OpenWorkshopAndTraining', 'OpenWorkshopAndTrainingWebJs');
}
export function logOpenedAdminSettings(params) {
  return _log(16443, 'OpenedAdminSettings', 'OpenedAdminSettingsWebJs', params);
}
export function logOpenedBillingInfoModal() {
  return _log(17745, 'OpenedBillingInfoModal', 'OpenedBillingInfoModalWebJs');
}
export function logOpenedBrandkitTab(params) {
  return _log(24560, 'OpenedBrandkitTab', 'OpenedBrandkitTabWebJs', params);
}
export function logOpenedDemoModal() {
  return _log(22670, 'OpenedDemoModal', 'OpenedDemoModalWebJs');
}
export function logOpenedDocumentDashboardView(params) {
  return _log(23743, 'OpenedDocumentDashboardView', 'OpenedDocumentDashboardViewWebJs', params);
}
export function logOpenedInitiatorScreen(params) {
  return _log(23311, 'OpenedInitiatorScreen', 'OpenedInitiatorScreenWebJs', params);
}
export function logOpenedLogin(params) {
  return _log(22830, 'OpenedLogin', 'OpenedLoginWebJs', params);
}
export function logOpenedPaymentCardModal() {
  return _log(17751, 'OpenedPaymentCardModal', 'OpenedPaymentCardModalWebJs');
}
export function logOpenedQuickCreateDashboardView(params) {
  return _log(22819, 'OpenedQuickCreateDashboardView', 'OpenedQuickCreateDashboardViewWebJs', params);
}
export function logOpenedSamlLogin(params) {
  return _log(23708, 'OpenedSamlLogin', 'OpenedSamlLoginWebJs', params);
}
export function logOpenedTeamInvitationModal(params) {
  return _log(24042, 'OpenedTeamInvitationModal', 'OpenedTeamInvitationModalWebJs', params);
}
export function logPauseSubscription(params) {
  return _log(24594, 'PauseSubscription', 'PauseSubscriptionWebJs', params);
}
export function logPickLibraryBasketOption(params) {
  return _log(17935, 'PickLibraryBasketOption', 'PickLibraryBasketOptionWebJs', params);
}
export function logPlayDesignToVideoOnboarding() {
  return _log(20525, 'PlayDesignToVideoOnboarding', 'PlayDesignToVideoOnboardingWebJs');
}
export function logPlayPrezi(params) {
  return _log(14421, 'PlayPrezi', 'PlayPreziWebJs', params);
}
export function logPlayReferralIntroVideo() {
  return _log(18352, 'PlayReferralIntroVideo', 'PlayReferralIntroVideoWebJs');
}
export function logPlayVideo() {
  return _log(15618, 'PlayVideo', 'PlayVideoWebJs');
}
export function logPresentInFullScreen(params) {
  return _log(16671, 'PresentInFullScreen', 'PresentInFullScreenWebJs', params);
}
export function logPreviewVideoTemplateWithInteractiveDemo(params) {
  return _log(22924, 'PreviewVideoTemplateWithInteractiveDemo', 'PreviewVideoTemplateWithInteractiveDemoWebJs', params);
}
export function logPreziEndScreenShowed(params) {
  return _log(15066, 'PreziEndScreenShowed', 'PreziEndScreenShowedWebJs', params);
}
export function logPublishedBrandkit(params) {
  return _log(20423, 'PublishedBrandkit', 'PublishedBrandkitWebJs', params);
}
export function logRateSupportWidgetChat(params) {
  return _log(18696, 'RateSupportWidgetChat', 'RateSupportWidgetChatWebJs', params);
}
export function logRedirectLibraryToFallback(params) {
  return _log(17789, 'RedirectLibraryToFallback', 'RedirectLibraryToFallbackWebJs', params);
}
export function logReferralInviteEmailSent(params) {
  return _log(18354, 'ReferralInviteEmailSent', 'ReferralInviteEmailSentWebJs', params);
}
export function logRejectAllCookies(params) {
  return _log(24507, 'RejectAllCookies', 'RejectAllCookiesWebJs', params);
}
export function logRemoveAsAdmin(params) {
  return _log(14431, 'RemoveAsAdmin', 'RemoveAsAdminWebJs', params);
}
export function logRemoveDocumentFromFolder(params) {
  return _log(23753, 'RemoveDocumentFromFolder', 'RemoveDocumentFromFolderWebJs', params);
}
export function logRemoveFolderCollaborator(params) {
  return _log(15466, 'RemoveFolderCollaborator', 'RemoveFolderCollaboratorWebJs', params);
}
export function logRemoveGramFromFolder(params) {
  return _log(17318, 'RemoveGramFromFolder', 'RemoveGramFromFolderWebJs', params);
}
export function logRemoveItemFromMyLibrary(params) {
  return _log(17077, 'RemoveItemFromMyLibrary', 'RemoveItemFromMyLibraryWebJs', params);
}
export function logRemovePresentationFromFolder(params) {
  return _log(14438, 'RemovePresentationFromFolder', 'RemovePresentationFromFolderWebJs', params);
}
export function logRemoveTeamMember(params) {
  return _log(14442, 'RemoveTeamMember', 'RemoveTeamMemberWebJs', params);
}
export function logRenameGram(params) {
  return _log(17320, 'RenameGram', 'RenameGramWebJs', params);
}
export function logRenamedMyLibraryItem(params) {
  return _log(17073, 'RenamedMyLibraryItem', 'RenamedMyLibraryItemWebJs', params);
}
export function logRequestDemo(params) {
  return _log(23292, 'RequestDemo', 'RequestDemoWebJs', params);
}
export function logRequestedBrowserNotificationsPermission() {
  return _log(14894, 'RequestedBrowserNotificationsPermission', 'RequestedBrowserNotificationsPermissionWebJs');
}
export function logResentTeamMemberInvitation(params) {
  return _log(14444, 'ResentTeamMemberInvitation', 'ResentTeamMemberInvitationWebJs', params);
}
export function logRestartPrezi(params) {
  return _log(14447, 'RestartPrezi', 'RestartPreziWebJs', params);
}
export function logRetryOpenVideoApp() {
  return _log(16715, 'RetryOpenVideoApp', 'RetryOpenVideoAppWebJs');
}
export function logReusePaymentMethod(params) {
  return _log(22647, 'ReusePaymentMethod', 'ReusePaymentMethodWebJs', params);
}
export function logRevokedAccessToken(params) {
  return _log(23314, 'RevokedAccessToken', 'RevokedAccessTokenWebJs', params);
}
export function logSalesLeadUploadFailed(params) {
  return _log(24253, 'SalesLeadUploadFailed', 'SalesLeadUploadFailedWebJs', params);
}
export function logSamlLoggedInSuccess(params) {
  return _log(16978, 'SamlLoggedInSuccess', 'SamlLoggedInSuccessWebJs', params);
}
export function logSaveAllFrames(params) {
  return _log(17937, 'SaveAllFrames', 'SaveAllFramesWebJs', params);
}
export function logSaveBillingAddress(params) {
  return _log(17743, 'SaveBillingAddress', 'SaveBillingAddressWebJs', params);
}
export function logSaveBrandkit(params) {
  return _log(20425, 'SaveBrandkit', 'SaveBrandkitWebJs', params);
}
export function logSaveFrame(params) {
  return _log(17969, 'SaveFrame', 'SaveFrameWebJs', params);
}
export function logSavePaymentCard(params) {
  return _log(17749, 'SavePaymentCard', 'SavePaymentCardWebJs', params);
}
export function logSavePersonalVideoReferralInvite() {
  return _log(18368, 'SavePersonalVideoReferralInvite', 'SavePersonalVideoReferralInviteWebJs');
}
export function logSaveReferralInvitePersonalNote() {
  return _log(18360, 'SaveReferralInvitePersonalNote', 'SaveReferralInvitePersonalNoteWebJs');
}
export function logSaveSamlSettings(params) {
  return _log(16449, 'SaveSamlSettings', 'SaveSamlSettingsWebJs', params);
}
export function logSavedMyLibraryItem(params) {
  return _log(22533, 'SavedMyLibraryItem', 'SavedMyLibraryItemWebJs', params);
}
export function logScrollNotificationCenterMessages(params) {
  return _log(21994, 'ScrollNotificationCenterMessages', 'ScrollNotificationCenterMessagesWebJs', params);
}
export function logScrollWelcomeTemplates(params) {
  return _log(17807, 'ScrollWelcomeTemplates', 'ScrollWelcomeTemplatesWebJs', params);
}
export function logSearchContent(params) {
  return _log(17082, 'SearchContent', 'SearchContentWebJs', params);
}
export function logSearchMyDocuments(params) {
  return _log(18421, 'SearchMyDocuments', 'SearchMyDocumentsWebJs', params);
}
export function logSearchPresentation() {
  return _log(14463, 'SearchPresentation', 'SearchPresentationWebJs');
}
export function logSearchPresentations(params) {
  return _log(17084, 'SearchPresentations', 'SearchPresentationsWebJs', params);
}
export function logSearchPrezisClicked() {
  return _log(15074, 'SearchPrezisClicked', 'SearchPrezisClickedWebJs');
}
export function logSearchSupportWidgetKnowledgeBase(params) {
  return _log(18688, 'SearchSupportWidgetKnowledgeBase', 'SearchSupportWidgetKnowledgeBaseWebJs', params);
}
export function logSeePathStepPreview(params) {
  return _log(14468, 'SeePathStepPreview', 'SeePathStepPreviewWebJs', params);
}
export function logSeekChapter(params) {
  return _log(22926, 'SeekChapter', 'SeekChapterWebJs', params);
}
export function logSelectAllProjectCard(params) {
  return _log(20844, 'SelectAllProjectCard', 'SelectAllProjectCardWebJs', params);
}
export function logSelectExportBackgroundSetting(params) {
  return _log(22194, 'SelectExportBackgroundSetting', 'SelectExportBackgroundSettingWebJs', params);
}
export function logSelectPresentationForAnalyticsLink(params) {
  return _log(14470, 'SelectPresentationForAnalyticsLink', 'SelectPresentationForAnalyticsLinkWebJs', params);
}
export function logSelectedViewCreatingOptions() {
  return _log(20594, 'SelectedViewCreatingOptions', 'SelectedViewCreatingOptionsWebJs');
}
export function logSendReferralInviteSocial(params) {
  return _log(18358, 'SendReferralInviteSocial', 'SendReferralInviteSocialWebJs', params);
}
export function logSetPrivacy(params) {
  return _log(23775, 'SetPrivacy', 'SetPrivacyWebJs', params);
}
export function logSharePresentation(params) {
  return _log(17671, 'SharePresentation', 'SharePresentationWebJs', params);
}
export function logSharePublish(params) {
  return _log(17673, 'SharePublish', 'SharePublishWebJs', params);
}
export function logShowPagedTranscript(params) {
  return _log(14486, 'ShowPagedTranscript', 'ShowPagedTranscriptWebJs', params);
}
export function logShowPptInsertOptions(params) {
  return _log(14898, 'ShowPptInsertOptions', 'ShowPptInsertOptionsWebJs', params);
}
export function logShowVideoWelcomeDialog() {
  return _log(15768, 'ShowVideoWelcomeDialog', 'ShowVideoWelcomeDialogWebJs');
}
export function logShowedEmailCapture(params) {
  return _log(15079, 'ShowedEmailCapture', 'ShowedEmailCaptureWebJs', params);
}
export function logShownClassicToNextModal() {
  return _log(19584, 'ShownClassicToNextModal', 'ShownClassicToNextModalWebJs');
}
export function logShownClassicToNextOnboarding() {
  return _log(19586, 'ShownClassicToNextOnboarding', 'ShownClassicToNextOnboardingWebJs');
}
export function logShownDashboardOnboarding(params) {
  return _log(20598, 'ShownDashboardOnboarding', 'ShownDashboardOnboardingWebJs', params);
}
export function logSignupFlowFailure(params) {
  return _log(22645, 'SignupFlowFailure', 'SignupFlowFailureWebJs', params);
}
export function logSignupFlowSuccess(params) {
  return _log(22643, 'SignupFlowSuccess', 'SignupFlowSuccessWebJs', params);
}
export function logSignupWithIntegrationWarning(params) {
  return _log(23320, 'SignupWithIntegrationWarning', 'SignupWithIntegrationWarningWebJs', params);
}
export function logSignupWithIntegration(params) {
  return _log(23318, 'SignupWithIntegration', 'SignupWithIntegrationWebJs', params);
}
export function logSignupWithProvider(params) {
  return _log(22936, 'SignupWithProvider', 'SignupWithProviderWebJs', params);
}
export function logSkipCreateTeam(params) {
  return _log(22693, 'SkipCreateTeam', 'SkipCreateTeamWebJs', params);
}
export function logSkipDashboardOnboarding(params) {
  return _log(20600, 'SkipDashboardOnboarding', 'SkipDashboardOnboardingWebJs', params);
}
export function logSkipInviteTeamMember(params) {
  return _log(22697, 'SkipInviteTeamMember', 'SkipInviteTeamMemberWebJs', params);
}
export function logSkipWelcomeScreen(params) {
  return _log(20610, 'SkipWelcomeScreen', 'SkipWelcomeScreenWebJs', params);
}
export function logStartedSalesLeadUpload(params) {
  return _log(23966, 'StartedSalesLeadUpload', 'StartedSalesLeadUploadWebJs', params);
}
export function logStoryBlockThumbnailClicked(params) {
  return _log(15099, 'StoryBlockThumbnailClicked', 'StoryBlockThumbnailClickedWebJs', params);
}
export function logSubmitContactSalesForm(params) {
  return _log(24513, 'SubmitContactSalesForm', 'SubmitContactSalesFormWebJs', params);
}
export function logSubmitCreateTeam(params) {
  return _log(22691, 'SubmitCreateTeam', 'SubmitCreateTeamWebJs', params);
}
export function logSubmitInviteTeamMember(params) {
  return _log(23928, 'SubmitInviteTeamMember', 'SubmitInviteTeamMemberWebJs', params);
}
export function logSubmitPaymentInfo() {
  return _log(14591, 'SubmitPaymentInfo', 'SubmitPaymentInfoWebJs');
}
export function logSubmitPaymentMethodDetails(params) {
  return _log(22653, 'SubmitPaymentMethodDetails', 'SubmitPaymentMethodDetailsWebJs', params);
}
export function logSubmitPaymentMethodSuccess(params) {
  return _log(22649, 'SubmitPaymentMethodSuccess', 'SubmitPaymentMethodSuccessWebJs', params);
}
export function logSubmitPersonalInfo() {
  return _log(14593, 'SubmitPersonalInfo', 'SubmitPersonalInfoWebJs');
}
export function logSubmitSupportWidgetOfflineChatForm(params) {
  return _log(18694, 'SubmitSupportWidgetOfflineChatForm', 'SubmitSupportWidgetOfflineChatFormWebJs', params);
}
export function logSubmittedIntentSurveyAnswer(params) {
  return _log(18330, 'SubmittedIntentSurveyAnswer', 'SubmittedIntentSurveyAnswerWebJs', params);
}
export function logSubmittedSegmentationAnswer(params) {
  return _log(15897, 'SubmittedSegmentationAnswer', 'SubmittedSegmentationAnswerWebJs', params);
}
export function logSubmittedSegmentationOnSignupScreen(params) {
  return _log(24485, 'SubmittedSegmentationOnSignupScreen', 'SubmittedSegmentationOnSignupScreenWebJs', params);
}
export function logSubmittedSubSegmentationAnswer(params) {
  return _log(15895, 'SubmittedSubSegmentationAnswer', 'SubmittedSubSegmentationAnswerWebJs', params);
}
export function logSubmittedViewerIdentificationForm(params) {
  return _log(14502, 'SubmittedViewerIdentificationForm', 'SubmittedViewerIdentificationFormWebJs', params);
}
export function logSuccessfulPaymentBuyNow(params) {
  return _log(15837, 'SuccessfulPaymentBuyNow', 'SuccessfulPaymentBuyNowWebJs', params);
}
export function logSuggestedSearchTermsClicked() {
  return _log(15109, 'SuggestedSearchTermsClicked', 'SuggestedSearchTermsClickedWebJs');
}
export function logSupportWidgetChatServedByAgent(params) {
  return _log(18680, 'SupportWidgetChatServedByAgent', 'SupportWidgetChatServedByAgentWebJs', params);
}
export function logSwitchPresentationModeDesignVideoPreview(params) {
  return _log(22204, 'SwitchPresentationModeDesignVideoPreview', 'SwitchPresentationModeDesignVideoPreviewWebJs', params);
}
export function logSwitchToPreziClassic() {
  return _log(19588, 'SwitchToPreziClassic', 'SwitchToPreziClassicWebJs');
}
export function logSwitchToPreziPresent() {
  return _log(19590, 'SwitchToPreziPresent', 'SwitchToPreziPresentWebJs');
}
export function logTeamMemberInvitationFailed(params) {
  return _log(24048, 'TeamMemberInvitationFailed', 'TeamMemberInvitationFailedWebJs', params);
}
export function logTeamMemberInvitationSuccess(params) {
  return _log(24045, 'TeamMemberInvitationSuccess', 'TeamMemberInvitationSuccessWebJs', params);
}
export function logTeaserContentClicked(params) {
  return _log(15107, 'TeaserContentClicked', 'TeaserContentClickedWebJs', params);
}
export function logTeaserContentHovered(params) {
  return _log(15122, 'TeaserContentHovered', 'TeaserContentHoveredWebJs', params);
}
export function logToggleAccountDropdown(params) {
  return _log(17844, 'ToggleAccountDropdown', 'ToggleAccountDropdownWebJs', params);
}
export function logTryWowDemo() {
  return _log(22087, 'TryWowDemo', 'TryWowDemoWebJs');
}
export function logTurnOffCamera() {
  return _log(22676, 'TurnOffCamera', 'TurnOffCameraWebJs');
}
export function logTurnOnCamera(params) {
  return _log(22730, 'TurnOnCamera', 'TurnOnCameraWebJs', params);
}
export function logUnlikePresentationPage(params) {
  return _log(15841, 'UnlikePresentationPage', 'UnlikePresentationPageWebJs', params);
}
export function logUnlikePublishPage(params) {
  return _log(17374, 'UnlikePublishPage', 'UnlikePublishPageWebJs', params);
}
export function logUnpublishedBrandkit() {
  return _log(22497, 'UnpublishedBrandkit', 'UnpublishedBrandkitWebJs');
}
export function logUpdateBillingAddress(params) {
  return _log(17747, 'UpdateBillingAddress', 'UpdateBillingAddressWebJs', params);
}
export function logUpdateBrandkit(params) {
  return _log(22499, 'UpdateBrandkit', 'UpdateBrandkitWebJs', params);
}
export function logUpdateCollaborator(params) {
  return _log(23766, 'UpdateCollaborator', 'UpdateCollaboratorWebJs', params);
}
export function logUpdateContactSalesFormData(params) {
  return _log(23245, 'UpdateContactSalesFormData', 'UpdateContactSalesFormDataWebJs', params);
}
export function logUpdatePaymentCard(params) {
  return _log(17753, 'UpdatePaymentCard', 'UpdatePaymentCardWebJs', params);
}
export function logUpdatedBillingAddress(params) {
  return _log(17741, 'UpdatedBillingAddress', 'UpdatedBillingAddressWebJs', params);
}
export function logUpdatedPaymentCard(params) {
  return _log(17739, 'UpdatedPaymentCard', 'UpdatedPaymentCardWebJs', params);
}
export function logUpdatedSaml(params) {
  return _log(16457, 'UpdatedSaml', 'UpdatedSamlWebJs', params);
}
export function logUpgradeLicense(params) {
  return _log(20838, 'UpgradeLicense', 'UpgradeLicenseWebJs', params);
}
export function logUpgradeToTeam(params) {
  return _log(23866, 'UpgradeToTeam', 'UpgradeToTeamWebJs', params);
}
export function logUploadBrandkitCustomFonts() {
  return _log(19543, 'UploadBrandkitCustomFonts', 'UploadBrandkitCustomFontsWebJs');
}
export function logUploadedBrandkitCustomFonts() {
  return _log(19545, 'UploadedBrandkitCustomFonts', 'UploadedBrandkitCustomFontsWebJs');
}
export function logUploadedSalesLead(params) {
  return _log(23968, 'UploadedSalesLead', 'UploadedSalesLeadWebJs', params);
}
export function logUseDeepLink(params) {
  return _log(14868, 'UseDeepLink', 'UseDeepLinkWebJs', params);
}
export function logUseWebTemplate(params) {
  return _log(15603, 'UseWebTemplate', 'UseWebTemplateWebJs', params);
}
export function logViewGramDownloadOptions(params) {
  return _log(17322, 'ViewGramDownloadOptions', 'ViewGramDownloadOptionsWebJs', params);
}
export function logViewPageSearchSubmit(params) {
  return _log(15131, 'ViewPageSearchSubmit', 'ViewPageSearchSubmitWebJs', params);
}
export function logViewTeamMemberList(params) {
  return _log(23924, 'ViewTeamMemberList', 'ViewTeamMemberListWebJs', params);
}
export function logVisitPricingPage(params) {
  return _log(15605, 'VisitPricingPage', 'VisitPricingPageWebJs', params);
}
export function logVisitProductPage(params) {
  return _log(15150, 'VisitProductPage', 'VisitProductPageWebJs', params);
}
export function logVisitProjectFromAnalytics(params) {
  return _log(24467, 'VisitProjectFromAnalytics', 'VisitProjectFromAnalyticsWebJs', params);
}
export function logVisitSignupPage(params) {
  return _log(17106, 'VisitSignupPage', 'VisitSignupPageWebJs', params);
}
export function logWelcomeAction(params) {
  return _log(17805, 'WelcomeAction', 'WelcomeActionWebJs', params);
}
export function logZoomIn(params) {
  return _log(14555, 'ZoomIn', 'ZoomInWebJs', params);
}
export function logZoomOut(params) {
  return _log(14561, 'ZoomOut', 'ZoomOutWebJs', params);
}