import * as Cookies from 'js-cookie';

import {
    logAcceptAllCookies,
    logChangedCookieConsent,
    logRejectAllCookies,
    logLoadedCookieBanner,
    // @ts-ignore: until tree-shaking is fixed
} from '@prezi/client-logger-module/lib/esm/glassbox/WebJs';

export const EVENT_PREZI_COOKIE_CONSENT_CHANGED = 'prezi.cookie.consent.changed';

enum OnetrustCookiesCategories {
    ESSENTIAL = 'C0001',
    PERFORMANCE = 'C0002',
    FUNCTIONAL = 'C0003',
    TARGETING = 'C0004',
}

interface OneTrustCustomEvent {
    detail: Array<OnetrustCookiesCategories>;
}

type CookieCategories = Array<OnetrustCookiesCategories> | string;

declare global {
    interface Window {
        cookieConsentNeeded: boolean;
        OptanonWrapper: () => void;
        OnetrustActiveGroups: string;
        OneTrust?: {
            OnConsentChanged: (handler: (event: OneTrustCustomEvent) => void) => void;
        };
    }
}

function dispatchCookieConsentChangedEvent(cookieCategories: CookieCategories) {
    window.dispatchEvent(
        new CustomEvent(EVENT_PREZI_COOKIE_CONSENT_CHANGED, {
            detail: {
                performanceCookiesAllowed: cookieCategories.includes(OnetrustCookiesCategories.PERFORMANCE),
                functionalCookiesAllowed: cookieCategories.includes(OnetrustCookiesCategories.FUNCTIONAL),
                targetingCookiesAllowed: cookieCategories.includes(OnetrustCookiesCategories.TARGETING),
            },
        }),
    );
}

function onConsentChanged(event: OneTrustCustomEvent): void {
    if (event.detail) {
        const cookieCategories = event.detail;
        dispatchCookieConsentChangedEvent(cookieCategories);
        logChangedCookieConsent({
            cookie_categories: cookieCategories.join(','),
        });
        if (cookieCategories.length === Object.keys(OnetrustCookiesCategories).length) {
            logAcceptAllCookies();
        }
    }
}

function logRejectAllButtonClicked() {
    logRejectAllCookies({
        reject_consent_type: 'REJECT_ALL_BUTTON',
    });
}

function logCloseBannerClicked() {
    logRejectAllCookies({
        reject_consent_type: 'CLOSE_BANNER',
    });
}

// NOTE: we should have that check explicitly until another implementation of OptanonWrapper sits
// in the head of Prezi pages. Once it removed, we can rely on one single OptanonWrapper definition
// defined in the current module below. Since the current module will be loaded under `onetrust` FS condition
// we will assume consent needed by default and expose it to window as global indicator.
window.cookieConsentNeeded = true;
// window.OptanonWrapper will only exist in case OneTrust cookie banner is loaded on the page
// and this is the way to delay cookie setting until cookie consent is given by visitor.
// window.OptanonWrapper callback is fired on each time the interaction with the cookie banner happened,
// in particular: accepting/rejecting/customizing all cookies or particular cookie categories
let initialized = false;
window.OptanonWrapper = () => {
    if (!initialized) {
        initialized = true;

        // logging once only, before any interaction with the banner
        if (!Cookies.get('OptanonAlertBoxClosed')) {
            logLoadedCookieBanner();
        }

        if (window.OneTrust) {
            dispatchCookieConsentChangedEvent(window.OnetrustActiveGroups);
            window.OneTrust.OnConsentChanged(onConsentChanged);

            const rejectAllBannerButton = document.querySelector(
                '#onetrust-pc-sdk button.ot-pc-refuse-all-handler',
            ) as HTMLElement;
            const rejectAllSettingsButton = document.querySelector('#onetrust-reject-all-handler') as HTMLElement;
            const closeBannerIcon = document.querySelector('#onetrust-close-btn-container') as HTMLElement;
            if (rejectAllBannerButton) {
                rejectAllBannerButton.onclick = logRejectAllButtonClicked;
            }
            if (rejectAllSettingsButton) {
                rejectAllSettingsButton.onclick = logRejectAllButtonClicked;
            }
            if (closeBannerIcon) {
                closeBannerIcon.onclick = logCloseBannerClicked;
            }
        }
    }
};
